<!-- 产品管线 -->
<template>
  <div>
    <div class="header">
      <div>
        <h3>{{ text12 }}</h3>
      </div>
    </div>
    <div class="content_box">
      <div class="content">
        <h3>{{ text1 }}</h3>
        <h4>{{ text2 }}</h4>
        <ul class="list">
          <li class="first">
            <div class="div_one">{{ text3 }}</div>
            <div class="div_one line">{{ text4 }}</div>
            <div>{{ text5 }}</div>
            <div>{{ text5_2 }}</div>
            <div class="center">
              <h5>{{ text6 }}</h5>
              <p>
                <span>{{ text11 }}</span>
                <span>{{ text10 }}</span>
                <span>{{ text7 }}</span>
                <span>{{ text8 }}</span>
                <span>{{ text9 }}</span>
              </p>
            </div>
            <div>{{ text13 }}</div>
          </li>
          <li v-for="(item, idx) in productList" :key="idx" :class="{'active': item.isActive}">
            <div class="div_one" v-html="item.name"></div>
            <div v-html="item.target"></div>
            <div v-html="item.function"></div>
            <div v-html="item.tech"></div>

            <div v-if="item.evolve" class="heterogeneous">
              <div class="div_four" v-for="ite in item.evolve">
                  <div class="div_four-box">
                    <div class="before_bar" :style="{ width: ite.before, backgroundColor: ite.color, color: ite.color }"><p style="color: #fff;">{{ite.text}}</p></div>
                    <div class="after_bar" :style="{ width: ite.after }"></div>
                    <em>{{ ite.end }}</em>
                  </div>
              </div>
            </div>

            <div class="div_four" v-if="!item.evolve">
              <div class="div_four-box">
                <div class="before_bar" :style="{ width: item.before, backgroundColor: item.color, color: item.color }"></div>
                <div class="after_bar" :style="{ width: item.after }"></div>
                <em>{{ item.end }}</em>
              </div>
            <!-- <router-link v-if="item.link" :to="item.link"></router-link>
            <router-link v-else to=""></router-link> -->
          </div>
          <div v-html="item.partner" class="partner"></div>

          </li>
        </ul>

        <p v-html="cite" style="opacity: 0;"></p>
      </div>
    </div>
  </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
const productList = [
  {
    name: "FG-M108",
    target: "CLDN18.2",
    function: "消化道肿瘤",
    tech: "ADCC 增强型<br/>单抗",
    start: "",
    isActive: true,
    evolve: [
      {
        end: "2024",
        before: "73%",
        after: "10%",
        text: '胃/胃食管交界处腺癌'
      },
      {
        end: "2024",
        before: "58%",
        after: "10%",
        text: '胰腺癌'
      },
      {
        end: "2024",
        before: "39%",
        after: "10%",
        text: '胆管癌'
      }
    ],
  },
  {
    name: "FG-B901",
    target: "CD40/PD-L1",
    function: "实体瘤",
    tech: "对称型双特异抗体<br/>单域抗体",
    start: "",
    isActive: false,
    end: "2024",
    before: "35%",
    after: "13%",
  },
  {
    name: "FG-M131",
    target: "未披露",
    function: "外周T细胞淋巴瘤<br/>(PTCL)",
    tech: "非对称型单臂抗体<br/>KIH，ADC",
    start: "",
    isActive: false,
    end: "",
    before: "30%",
    after: "",
  },
  {
    name: "FG-B310",
    target: "未披露",
    function: "消化道肿瘤",
    tech: "非对称双抗<br/>ADC",
    start: "",
    isActive: false,
    end: "",
    before: "10%",
    after: "",
  },
  {
    name: "FG<br/>-B902/T903",
    target: "TIGIT/PVRIG<br/>/（PD-1）",
    function: "实体瘤",
    tech: "非对称双/三特异抗体<br/>单域抗体<br/>KIH&共用轻链",
    start: "",
    isActive: false,
    end: "",
    before: "25%",
    after: "",
  },
  {
    name: "FG-M701",
    target: "TL1A",
    function: "IBD",
    tech: "工程改造单抗",
    start: "",
    isActive: false,
    end: "2024",
    before: "35%",
    after: "13%",
    partner: "AbbVie<br/>（全球权益）",
  }
];
const productListEN = [
  {
    name: "FG-M108",
    target: "CLDN18.2",
    function: "GI Tumors",
    tech: "mAb<br/>ADCC enhanced",
    start: "",
    isActive: true,
    evolve: [
      {
        end: "2024",
        before: "73%",
        after: "10%",
        text: 'GC/CEJ Cancer'
      },
      {
        end: "2024",
        before: "58%",
        after: "10%",
        text: 'Pancreatic Cancer'
      },
      {
        end: "2024",
        before: "39%",
        after: "10%",
        text: 'Biliary Tract Cancer'
      }
    ],
  },
  {
    name: "FG-B901",
    target: "CD40/PD-L1",
    function: "Solid Tumors",
    tech: "Symmetric bispecific<br/>VHH",
    start: "",
    isActive: false,
    end: "2024",
    before: "35%",
    after: "13%",
  },
  {
    name: "FG-M131",
    target: "Undisclosed",
    function: "Peripheral T-Cell<br/>Lymphoma (PTCL)",
    tech: "Asymmetric Single Arm<br/>KIH，ADC",
    start: "",
    isActive: false,
    end: "",
    before: "30%",
    after: "",
  },
  {
    name: "FG-B310",
    target: "Undisclosed",
    function: "GI Tumors",
    tech: "Bispecific, ADC",
    start: "",
    isActive: false,
    end: "",
    before: "10%",
    after: "",
  },
  {
    name: "FG<br/>-B902/T903",
    target: "TIGIT/PVRIG<br/>/（PD-1）",
    function: "Solid Tumors",
    tech: "Asymmetric<br/>bi-/tri-specific<br/>VHH + KIH & comLC",
    start: "",
    isActive: false,
    end: "",
    before: "25%",
    after: "",
  },
  {
    name: "FG-M701",
    target: "TL1A",
    function: "IBD",
    tech: "Engineered mAb",
    start: "",
    isActive: false,
    end: "2024",
    before: "35%",
    after: "13%",
    partner: "AbbVie<br/>（Global Rights）",
  }
];
import { mapState } from "vuex";
export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text1: "明济研发管线",
          text2: "公司产品聚焦癌症及免疫, 管线丰富平衡，多款创新靶点生物药。",
          text3: "产品",
          text4: "靶点",
          text5: "适应症",
          text5_2: "应用技术",
          text6: "研发进展",
          productList,
          text7: "临床I期",
          text8: "临床II期",
          text9: "临床lll期",
          text10: "临床前",
          text11: "发现",
          text12: "产品管线及进展",
          text13: "合作伙伴",
          cite: "以供参考:</br>\n\
*FG-M108=M108; FG-B901=B901<br/>\n\
*PTCL: Peripheral T-cell Lymphomas; ADs: Autoimmune Diseases",
        };
        break;
      case "en":
        return {
          text1: "Pipeline",
          text2: "FutureGen′s pipeline consists of the innovative biologics focusing on oncology and immunology therapeutic areas.",
          text3: "Products",
          text4: "Target",
          text5: "Indication",
          text5_2: "Technology",
          text6: "R&D Progress",
          productList: productListEN,
          text7: "Phase I",
          text8: "Phase II",
          text9: "Phase III",
          text10: "Preclinical",
          text11: "Discovery",
          text12: "Pipeline and Progress",
          text13: "Partnership",
          cite: "Notes:</br>\n\
*FG-M108=M108; FG-B901=B901<br/>\n\
*PTCL: Peripheral T-cell Lymphomas; ADs: Autoimmune Diseases",
        };
        break;
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
};
</script>

<style lang="less" scoped>
body {
  overflow-x: hidden;
}

.header {
  position: relative;
  height: 400px;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/chanpin/header.png") no-repeat;
  background-size: 100% 100%;
  div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    h3 {
      position: relative;
      font-size: 50px;
      color: #fff;
      padding-top: 20%;
    }
    h3::after {
      position: absolute;
      top: 110%;
      left: 0%;
      content: "";
      width: 50px;
      height: 2px;
      background-color: #fff;
    }
  }
}
.content_box {
  background-color: rgba(204, 204, 204, 0.2);
  overflow: hidden;
  .content {
    box-sizing: border-box;
    width: 1600px;
    margin: 80px auto 0 auto;
    h3 {
      position: relative;
      color: #203864;
      font-weight: normal;
      font-size: 50px;
      text-align: center;
    }
    h3::after {
      position: absolute;
      top: 115%;
      left: 48%;
      width: 50px;
      height: 2px;
      content: "";
      background: #ccc;
    }
    h4 {
      color: #203864;
      font-weight: normal;
      font-size: 18px;
      text-align: center;
      margin-top: 40px;
    }
    > p {
      width: 80vw;
      display: block;
      margin: 30px auto 80px auto;
      color: #203864;
      line-height: 1.6;
    }
    .list {
      list-style: none;
      margin: 40px auto 0 auto;
      width: 80vw;
      li {
        display: flex;
        align-items: center;
        margin-top: 5px;
        > div {
          width: 18%;
          padding: 0;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          margin-left: 2px;
          font-size: 16px;
          background-color: #fff;
          text-align: center;
        }
        > div:first-child {
          width: 10%;
        }
        .center {
          width: 55%;
          h5 {
            font-size: 1em;
            font-weight: normal;
            display: block;
          }
          p {
            font-size: 0.8em;
            opacity: 0.8;
            width: 100%;
            text-align: left;
            display: block;
            span {
              border-right: 2px solid;
              width: calc(19% - 4px);
              display: inline-block;
              box-sizing: border-box;
              text-align: center;
            }
            span:last-child {
              border: none;
            }
          }
        }
        .div_four {
          width: 55%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 25px 0;
          .div_four-box {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 13px;
            position: relative;
            .before_bar {
              position: relative;
              width: 0;
              height: 30px;
              font-weight: normal;
              text-align: right;
              color: #fff;
              background-color: #203864;
              color: #203864;
              z-index: 2;
              flex-shrink: 0;
            }
            .before_bar::after {
              content: "";
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(100%, -50%);
              background: transparent;
              border: 25px solid transparent;
              border-left: 25px solid;
            }
            .after_bar {
              position: relative;
              width: 0;
              height: 30px;
              color: #333;
              border: 1px solid #ccc;
              border-right: transparent;
              z-index: 1;
              flex-shrink: 0;
              text-align: right;
              background: #84bffd;
            }
            .after_bar::after {
              content: "";
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(100%, -50%);
              background: transparent;
              border: 25px solid transparent;
              border-left: 25px solid #84bffd;
            }
            em {
              display: block;
              transform: translateX(-100%);
              position: relative;
              z-index: 3;
              font-style: normal;
              color: #fff;
            }
          }
          a {
            width: 30px;
            height: 30px;
            margin-right: 20px;
            background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/chanpin/jiantou.png") no-repeat;
          }
        }
        .heterogeneous {
          width: 55%;
          height: 160px;
          padding: 0;
          display: block;
          .div_four {
            width: 100%;
            padding: 10px 0;
            .before_bar {
              p {
                height: 100%;
                text-align: center;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
      .active {
        > div {
          height: 160px;
        }
      }
      .first {
        div {
          color: #fff;
          font-size: 24px;
          background: #203864;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
      }
    }
    .partner {
      font-size: 18px;
      color: #203864;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 750px) {
  .header {
    height: 150px;
    div {
      h3 {
        font-size: 24px;
        margin-left: 10%;
        font-weight: normal;
        padding-top: 20%;
      }
      h3::after {
        width: 30px;
      }
    }
  }
  .content_box {
    .content {
      margin: 20px auto 0 auto;
      width: 100vw;
      h3 {
        font-size: 30px;
      }
      h3::after {
        display: none;
      }
      h4 {
        font-size: 16px;
        width: 80%;
        margin: 18px auto 0 auto;
      }
      > p {
        width: 95%;
        margin: 15px auto;
        font-size: 14px;
      }
      .list {
        margin: 40px auto 0 auto;
        overflow: hidden;
        overflow-x: scroll;
        width: 100vw;
        li {
          width: 1600px;
        }
        .first {
          font-weight: 600;
          div {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
